<template>
  <div class="resize">
    <div class="l-col">
      <base-input
        v-model="name"
        :placeholder="$t('name.placeholder')"
        :label="$t('name.label')"
        class="form__field--input"
        @input="onChange"
      >
      </base-input>
    </div>

    <div class="l-col">
      <!--        :pattern="pattern ? key : 'error'"-->
      <!--        :custom-error-messages="customValidationMsgs.confirm"-->
      <!--        use-reactive-validation-->
      <base-input
        v-model="key"
        :placeholder="$t('key.placeholder')"
        type="textarea"
        size="medium"
        :label="$t('key.label')"
        class="form__field--input"
        @input="onChange"
      >
        <!--        <template #after>-->
        <!--          <label v-if="!pattern" style="color: red" :tooltip="$t('confirm')">-->
        <!--            {{ 'Неверный формат' }}-->
        <!--          </label>-->
        <!--        </template>-->
      </base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import translit from '@/mixins/translit';
export default {
  name: 'NewSsh',
  components: { BaseInput },
  mixins: [setFocus, translit],
  props: {},
  data() {
    return {
      customValidationMsgs: {
        confirm: { patternMismatch: "this.$t('fields')" },
      },
      key: '',
      name: '',
    };
  },
  computed: {
    // pattern() {
    //   return /^(ssh-ed25519 AAAAC3NzaC1lZDI1NTE5|sk-ssh-ed25519@openssh.com AAAAGnNrLXNzaC1lZDI1NTE5QG9wZW5zc2guY29t|ssh-rsa AAAAB3NzaC1yc2)[0-9A-Za-z+/]+[=]{0,3}(\s.*)?$/.test(
    //     this.key
    //   );
    // },
  },
  watch: {
    // key: function (value) {
    //   const isValidKey =
    //     /^(ssh-ed25519 AAAAC3NzaC1lZDI1NTE5|sk-ssh-ed25519@openssh.com AAAAGnNrLXNzaC1lZDI1NTE5QG9wZW5zc2guY29t|ssh-rsa AAAAB3NzaC1yc2)[0-9A-Za-z+/]+[=]{0,3}(\s.*)?$/.test(
    //       value
    //     );
    //   isValidKey ? this.$emit('ready') : this.$emit('notready');
    // },
  },
  mounted() {
    this.$emit('notready');
  },
  methods: {
    onChange() {
      // console.log(event);
      // console.log(this.name && this.pattern);
      // if (this.name && this.pattern) {
      if (this.name && this.key) {
        const preparedText = this.translit(this.name).replaceAll(' ', '').trim();

        return this.$emit('change', {
          // name: this.name,
          name: preparedText,
          key: this.key,
        });
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "add_file": "Выбрать файл",
    "name": {
      "label": "Имя пары",
      "placeholder": "Имя SSH-ключа"
    },
    "key": {
      "label": "Публичный ключ",
      "placeholder": "Укажите публичный ключ в формате OpenSSH"
    },
    "fields": "Паттерн фейл",
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    },
    "sure": {
      "close": "Закрыть",
      "success": "Ключ успешно добавлен",
      "key": "SSH-ключ успешно добавлен"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
